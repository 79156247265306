import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProjectDashboardService {
  URL: string = environment.BASE_URL;
  httpOptions;

  constructor(private http: HttpClient, private cookieService: CookieService) {
    this.httpOptions = {
      headers: new HttpHeaders({
        Authorization: cookieService.get('pm_token'),
      }),
    };
  }

  getAllprojects(): Observable<any> {
    console.log(this.URL + `/projects/getall`);
    return this.http.get(this.URL + `/projects/getall`, this.httpOptions);
  }
  
  getProjectDashboard(_data): Observable<any> {
    console.log(_data);
    //return this.http.get( `http://85.208.48.114:8552/projects/getProjectDashboard?ProjectId=473&Month=11&Year=2022` , this.httpOptions);
    return this.http.get(
      this.URL +
        `/projects/getProjectDashboard?ProjectId=${_data.ProjectId}&Month=${_data.Month}&Year=${_data.Year}`,
      this.httpOptions
    );
  }

  getTimeTableProjects(params): Observable<any> {
    for (const key in params) {
      if (typeof params[key] == 'object' && params[key].length) {
        params[key] = params[key].join(',');
      } else if (typeof params[key] == 'object' && !params[key].length) {
        params[key] = '';
      }
    }
    return this.http.post(
      this.URL + `/projects/getProjectDashboardTimeTable`,
      params,
      this.httpOptions
    );
  }

  getFianaceProjects(params): Observable<any> {
    for (const key in params) {
      if (typeof params[key] == 'object' && params[key].length) {
        params[key] = params[key].join(',');
      } else if (typeof params[key] == 'object' && !params[key].length) {
        params[key] = '';
      }
    }
    return this.http.post(
      this.URL + `/projects/getProjectDashboardTimeTable`,
      params,
      this.httpOptions
    );
  }

  getProjectsGroupByConractor(params): Observable<any> {
    for (const key in params) {
      if (typeof params[key] == 'object' && params[key].length) {
        params[key] = params[key].join(',');
      } else if (typeof params[key] == 'object' && !params[key].length) {
        params[key] = '';
      }
    }
    return this.http.post(
      this.URL + `/projects/getProjectsGroupByConractor`,
      params,
      this.httpOptions
    );
  }

  //http://85.208.48.114:8552/projects/getProjectsGroupByDeparts

  getProjectsGroupByDeparts(sentData) {
    const URL = `${environment.BASE_URL}/projects/getProjectsGroupByDeparts`;
    return this.http.post(URL, sentData, this.httpOptions);
  }

  getProjectsGroupByInitiative(sentData) {
    const URL = `${environment.BASE_URL}/projects/getProjectsGroupByInitiative`;
    return this.http.post(URL, sentData, this.httpOptions);
  }

  getProjectsGroupByStatus(sentData) {
    const URL = `${environment.BASE_URL}/projects/getProjectsGroupByStatus`;
    return this.http.post(URL, sentData, this.httpOptions);
  }

  getProjectsTotals(sentData) {
    const URL = `${environment.BASE_URL}/projects/getProjectsTotals`;
    return this.http.post(URL, sentData, this.httpOptions);
  }

  getProjectDetails(_data): Observable<any> {
    console.log(_data);
    return this.http.get(
      this.URL + `/projects/getdetails?ProjectId=${_data.ProjectId}`,
      this.httpOptions
    );
  }

  getProjectItems(_data): Observable<any> {
    return this.http.get(
      this.URL + `/projectitem/getProjectItems?ProjectId=${_data.ProjectId}`,
      this.httpOptions
    );
  }

  getProjectStatus(ProjectId) {
    const URL = `${environment.BASE_URL}/projects/getProjectStatus`;
    return this.http.get(URL, {
      params: { ProjectId },
      headers: this.httpOptions.headers,
    });
  }

  getProjectMonthlyExecution(sentData) {
    const URL = `${environment.BASE_URL}/projects/getProjectMonthlyExecution`;
    return this.http.post(URL, sentData, this.httpOptions);
  }

  
  getProjectItemsKpis(_data): Observable<any> {
    return this.http.get(
      this.URL + `/projects/getProjectItemsKpis?ProjectId=${_data.ProjectId}`, //473
      this.httpOptions
    );
  }



}
